import { render, staticRenderFns } from "./direccion.vue?vue&type=template&id=3400d9b8&scoped=true&"
import script from "./direccion.vue?vue&type=script&lang=js&"
export * from "./direccion.vue?vue&type=script&lang=js&"
import style0 from "./direccion.vue?vue&type=style&index=0&id=3400d9b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3400d9b8",
  null
  
)

export default component.exports