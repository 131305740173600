<template>
  <div class="miCuenta">
    <HeaderMenu />
    <div class="head-mc">
      <div class="head-mc-cont">
        <p class="titulo">MI CUENTA</p>
        <div class="info-cuenta">
          <div class="info-text">
            <p class="titulo">
              ¡HOLA,<br class="mo" />
              {{this.identity.fullname}}!
            </p>
            <p class="close">
              (¿No eres {{this.identity.fullname}}?
              <a @click="cerrarSesion()">Cerrar Sesión</a>)
            </p>
          </div>
          <!--urlpath.url()+'/P6H_3Pm/bt7-EO4/user-img/'+usuarios.image-->
          <img :src="urlpath.url()+'/P6H_3Pm/bt7-EO4/user-img/'+this.identity.image" alt="user"
           class="user-img" v-if="this.identity.image !== null" />
          <img src="../../assets/user.png" alt="user" class="user-img" v-else/>
        </div>
      </div>
      <div
        class="mi-cuenta-body"
        :class="{ 'p-mcb': this.$route.params.name == 'direccion' }"
      >
        <div class="nav-mc">
          <div class="nav-blue">
            <div
              class="nav-btn ic1"
              :class="{ 'blue-btn': this.$route.params.name == 'escritorio' }"
              @click="changeUrl('escritorio')"
            >
              ESCRITORIO
            </div>
            <div
              class="nav-btn ic2"
              :class="{ 'blue-btn': this.$route.params.name == 'pedidos' }"
              @click="changeUrl('pedidos')"
            >
              PEDIDOS
            </div>
            <div
              class="nav-btn ic3"
              :class="{ 'blue-btn': this.$route.params.name == 'direccion' || this.$route.params.name == 'agregar'}"
              @click="changeUrl('direccion')"
            >
              DIRECCIÓN
            </div>
            <div
              class="nav-btn ddlc ic4"
              :class="{
                'blue-btn': this.$route.params.name == 'detalles-de-la-cuenta',
              }"
              @click="changeUrl('detalles-de-la-cuenta')"
            >
              <span class="mo">DETALLES DE LA </span>
              <span>CUENTA</span>
            </div>
          </div>
          <div class="nav-btn exit-btn"  @click="cerrarSesion()">
            <img src="../../assets/door.svg" alt="door" /> SALIR
          </div>
        </div>
        <div class="tab-mc-cont">
          <div class="ct1" v-if="this.$route.params.name == 'escritorio'">
            <Escritorio />
          </div>
          <div class="ct1" v-if="this.$route.params.name == 'pedidos'">
            <Pedidos />
          </div>
          <div class="ct1" v-if="this.$route.params.name == 'direccion' 
           || this.$route.params.name == 'agregar'">
            <Direccion :ident="this.identity.sub"/>
          </div>
          <div
            class="ct1"
            v-if="this.$route.params.name == 'detalles-de-la-cuenta'">
            <Detalles />
          </div>
        </div>
      </div>
    </div>       

        <div v-if="viewModal" class="modal_view">
           
            <div id="modal_logout">
                <div class="body">
                     <img src="../../assets/logout.png" alt="icono alerta">
            <h3 > <span>Cerrar Sesión</span></h3>
                </div>
           
            <div class="modal_log_btns dflx">
                <p @click="logout()">Aceptar</p>   <p @click="cancelar()">Cancelar</p>
            </div>
          </div>
        </div>
    <Footer />
  </div>
</template>
<script>
import HeaderMenu from "../../components/fixedItems/HeaderMenu.vue";
import Footer from "../../components/fixedItems/Footer.vue";
import Escritorio from "./escritorio.vue";
import Pedidos from "./pedidos.vue";
import Direccion from "./direccion.vue";
import Detalles from "./detalles.vue";

let urlpath = require("../../global/url");
import { mapActions } from "vuex";

export default {
  data() {
    return {
      miCuenta: 1,
      viewModal:false,urlpath,
    };
  },
  components: {
    HeaderMenu,
    Footer,
    Escritorio,
    Pedidos,
    Direccion,
    Detalles,
  },
      metaInfo(){
        return{
            title: 'Mi Cuenta',
            titleTemplate: "%s | Agriga de México",
            htmlAttrs: {
            lang: "es",
            },
            meta: [
            { charset: "utf-8" },
            {
                name: "description",
                content: "Mi cuenta de Agriga de México.",
            },
            { name: "viewport", content: "width=device-width, initial-scale=1" },
            ],            
        };
      },
  async created() {
    await this.getIdentity();
  },
  computed: {
    identity() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
  methods: {

    changeSec(num) {
      this.miCuenta = num;
    },
    changeUrl(num) {
    
      return this.$router.push("/mi-cuenta/" + num);
    },
    changeUrl2(num,id) {
      
      return this.$router.push("/mi-cuenta/" + num);
    },
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    getIdentity: async function () {
      let response = await this.$store.dispatch("admin/getData");
      return response;
    },
    cerrarSesion() {
      this.viewModal = true;
    },
    cancelar() {
      this.viewModal = false;
    },
    logout: async function () {
      let response = await this.$store.dispatch("admin/logout");
      this.wait();
    },
  },
  /*watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                let name= this.titulo;
                document.title = to.meta.title || `Mi Cuenta - Agriga de México`;
            }
        },
  }*/
};
</script>
<style scoped>
.head-mc-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e3f1e5;
  padding: 3.229vw 17.031vw;
}
.info-cuenta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head-mc-cont .titulo {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 3.385vw;
  line-height: 4.063vw;
  text-align: center;
  color: #fe9128;
  margin: 0;
}
.head-mc-cont .info-cuenta .titulo {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.563vw;
  line-height: 1.875vw;
  text-align: right;
  color: #3ebdb1;
}
.head-mc-cont .info-cuenta .titulo br {
  display: none;
}
.head-mc-cont p.close {
  font-family: "Helvetica World Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 0.938vw;
  line-height: 1.354vw;
  text-align: right;
  color: #3ebdb1;
  margin: 0;
}
.head-mc-cont p.close a {
  color: #fe9128;
  cursor: pointer;
  text-decoration-color: transparent;
}
.info-text {
  padding-right: 1.875vw;
}
.mi-cuenta-body {
  padding: 3.281vw 17.031vw;
}
.p-mcb {
  padding: 3.281vw 7.448vw;
}
.nav-mc {
  display: flex;
  margin: 0 auto 3.438vw;
  justify-content: center;
}
.nav-blue {
  display: flex;
  border-top: 1px solid #3ebdb1;
  border-bottom: 1px solid #3ebdb1;
}
.nav-btn {
  width: 12.708vw;
  height: 4.271vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #3ebdb1;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.042vw;
  line-height: 1.25vw;
  color: #3ebdb1;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}
.nav-btn.blue-btn {
  background: #3ebdb1;
  color: white;
}
.nav-btn.ddlc {
  width: 16.302vw;
}
.nav-btn.exit-btn {
  background: #fe9128;
  border: 1px solid #fe9128;
  color: white;
  width: 12.708vw;
}
.nav-btn.exit-btn img {
  width: 0.729vw;
  padding-right: 0.469vw;
}
@media (max-width: 768px) {
  .head-mc-cont .titulo {
    font-size: 11.682vw;
    line-height: 14.019vw;
    margin-bottom: 7.009vw;
  }
  .head-mc-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e3f1e5;
    padding: 15.421vw 13.654vw;
    flex-direction: column;
  }
  .info-cuenta {
    flex-direction: column-reverse;
  }
  .user-img {
    width: 32.477vw;
    height: 32.477vw;
    border-radius: 46.729vw;
  }
  .head-mc-cont .info-cuenta .titulo {
    font-size: 8.178vw;
    line-height: 9.813vw;
    text-align: center;
    margin: 3.738vw 0;
  }
  .head-mc-cont .info-cuenta .titulo br {
    display: block;
  }
  .head-mc-cont p.close {
    font-size: 5.607vw;
    line-height: 7.944vw;
    text-align: center;
  }
  .nav-mc {
    background: #fafafa;
    position: fixed;
    /*z-index: 500;*/
    z-index: 2100;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
    overflow: hidden;
  }
  .nav-btn.exit-btn {
    display: none;
  }
  .nav-blue {
    display: flex;
    border: none;
  }
  .nav-btn,
  .nav-btn.ddlc {
    width: 24.508vw;
    height: 23.364vw;
    font-size: 2.142vw;
    line-height: 11.25vw;
    font-family: "Helvetica World Regular";
    align-items: flex-end;
    border-left: none;
  }
  .nav-btn.ddlc .mo {
    display: none;
  }
  .mi-cuenta-body {
    padding: 14.72vw 8.682vw;
  }
  .nav-btn.blue-btn {
    background: white;
    color: #fe9128;
  }
  .ic1,
  .ic2,
  .ic3,
  .ic4 {
    background-size: 7.477vw;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 4.439vw;
  }
  .ic1 {
    background-image: url("../../assets/menu-1.svg");
  }
  .ic2 {
    background-image: url("../../assets/menu-2.svg");
  }
  .ic3 {
    background-image: url("../../assets/menu-3.svg");
  }
  .ic4 {
    background-image: url("../../assets/menu--4.svg");
  }

  .nav-btn.blue-btn.ic1,
  .nav-btn.blue-btn.ic2,
  .nav-btn.blue-btn.ic3,
  .nav-btn.blue-btn.ic4 {
    background-size: 7.477vw;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 4.439vw;
  }
  .nav-btn.blue-btn.ic1 {
    background-image: url("../../assets/menu-h1.svg");
  }
  .nav-btn.blue-btn.ic2 {
    background-image: url("../../assets/menu-h2.svg");
  }
  .nav-btn.blue-btn.ic3 {
    background-image: url("../../assets/menu-h3.svg");
  }
  .nav-btn.blue-btn.ic4 {
    background-image: url("../../assets/menu-h4.svg");
  }
}
@media (min-width: 768px) {
  .nav-btn span.mo {
    padding-right: 0.2vw;
}
}
</style>